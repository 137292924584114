import type { AsyncDataOptions, NuxtApp } from '#app';

// Define the getCachedData function
const getCachedData = (key: string, nuxtApp: NuxtApp) =>
  nuxtApp.payload.data[key] || nuxtApp.static.data[key];

// Define the useAsyncCache composable
function useAsyncCache<DataT, DataE>(
  keyOrHandler: string | ((nuxtApp?: NuxtApp) => Promise<DataT>),
  handlerOrOptions?:
    | ((nuxtApp?: NuxtApp) => Promise<DataT>)
    | AsyncDataOptions<DataT>,
  options?: AsyncDataOptions<DataT> & { lazy?: boolean },
) {
  // Determine if the first argument is a key or a handler function
  const isKey = typeof keyOrHandler === 'string';
  const key = isKey ? keyOrHandler : undefined;
  const handler = isKey
    ? (handlerOrOptions as (nuxtApp?: NuxtApp) => Promise<DataT>)
    : (keyOrHandler as (nuxtApp?: NuxtApp) => Promise<DataT>);
  const finalOptions = isKey
    ? options
    : (handlerOrOptions as AsyncDataOptions<DataT> | undefined);

  const enhancedOptions: AsyncDataOptions<DataT> = {
    ...finalOptions,
    getCachedData,
  };

  // Call the selected data fetching function with the enhanced options
  if (isKey) {
    return useAsyncData<DataT, DataE>(key!, handler, enhancedOptions);
  } else {
    return useAsyncData<DataT, DataE>(handler, enhancedOptions);
  }
}

export default useAsyncCache;
